<template>
  <div class="app-download">
    <div class="container">
      <div class="columns is-vcentered">
        <div class="column is-12">
          <h1 class="title">Die EiS App im Play Store und im App Store</h1>
        </div>
      </div>
      <div class="columns is-vcentered">
        <div class="column">
          <a
            href="https://play.google.com/store/apps/details?id=de.woerterfabrik.eis_app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          >
            <img
              class="play-store-badge"
              alt="Jetzt bei Google Play"
              src="../assets/google-play-badge.png"
          /></a>
        </div>
        <div class="column">
          <a href="https://apps.apple.com/de/app/eis-app/id1481083656">
            <img
              class="app-store-badge"
              alt="Download im Apple AppStore"
              src="../assets/AppStore.png"
          /></a>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="content has-text-centered">
        <p>
          Google Play und das Google Play Logo sind Marken von Google LLC.<br />
          App Store und das App Store Logo sind Marken von Apple Inc.<br />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "app-download",
  components: {},
  created: function () {
    this.$store.dispatch("setCurrentView", "AppDownload");
  },
};
</script>
